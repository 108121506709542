



















import { defineComponent, ref } from '@vue/composition-api';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { popPersistedActualLocation } from '@/utils';
import { useAccountInfoStore } from '@/stores/account-info';
import AppSetupCard from '@/components/shared/SetupCard.vue';

export default defineComponent({
  name: 'Welcome',
  components: { AppSetupCard },
  setup(props, { root }) {
    const { firstName } = storeToRefs(useAuthStore());
    const loading = ref<boolean>(false);

    const confirmAndLeave = (goTo: string): void => {
      const accountInfoStore = useAccountInfoStore();
      loading.value = true;
      try {
        accountInfoStore.confirmWelcomePage();
        const persistedLocation = popPersistedActualLocation();
        root.$router.push(persistedLocation || goTo);
      } catch (err) {
        console.error(err);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      firstName,
      confirmAndLeave,
    };
  },
});
